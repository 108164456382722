import { Path } from 'react-konva'
import { FC } from 'react'
import { PointIconProps } from '../point-status.types'

export const LockIcon: FC<PointIconProps> = ({
  width = 40,
  height = 40,
  color = '#1E22AA'
}) => {
  const scaleX = width / 134
  const scaleY = height / 166

  return (
    <>
      <Path
        data="M112.333 60.7501H103.167V36.3334C103.167 26.7414 99.3563 17.5423 92.5737 10.7597C85.7911 3.97715 76.592 0.166748 67 0.166748C57.408 0.166748 48.2089 3.97715 41.4263 10.7597C34.6437 17.5423 30.8333 26.7414 30.8333 36.3334V60.7501H21.6667C16.1413 60.7501 10.8423 62.945 6.93529 66.852C3.02828 70.759 0.833344 76.0581 0.833344 81.5834V145C0.848737 150.521 3.04861 155.811 6.95229 159.714C10.856 163.618 16.1461 165.818 21.6667 165.833H112.333C117.854 165.818 123.144 163.618 127.048 159.714C130.951 155.811 133.151 150.521 133.167 145V81.5834C133.167 76.0581 130.972 70.759 127.065 66.852C123.158 62.945 117.859 60.7501 112.333 60.7501ZM39.1667 36.3334C39.1667 28.9516 42.0991 21.872 47.3189 16.6523C52.5386 11.4325 59.6181 8.50008 67 8.50008C74.3819 8.50008 81.4614 11.4325 86.6811 16.6523C91.9009 21.872 94.8333 28.9516 94.8333 36.3334V60.7501H39.1667V36.3334ZM124.833 145C124.809 148.308 123.485 151.473 121.146 153.812C118.807 156.151 115.641 157.476 112.333 157.5H21.6667C18.3589 157.476 15.1934 156.151 12.8544 153.812C10.5153 151.473 9.19067 148.308 9.16668 145V81.5834C9.16668 78.2682 10.4836 75.0888 12.8278 72.7446C15.172 70.4004 18.3515 69.0834 21.6667 69.0834H112.333C115.649 69.0834 118.828 70.4004 121.172 72.7446C123.516 75.0888 124.833 78.2682 124.833 81.5834V145Z"
        fill={color}
        scaleX={scaleX}
        scaleY={scaleY}
      />
      <Path
        data="M75.3333 107.583C75.3382 109.04 74.9547 110.472 74.2223 111.732C73.49 112.992 72.4353 114.033 71.1667 114.75V127.25C71.1667 128.355 70.7277 129.415 69.9463 130.196C69.1649 130.978 68.1051 131.417 67 131.417C65.8949 131.417 64.8351 130.978 64.0537 130.196C63.2723 129.415 62.8333 128.355 62.8333 127.25V114.75C61.5647 114.033 60.51 112.992 59.7777 111.732C59.0453 110.472 58.6618 109.04 58.6667 107.583C58.6667 105.373 59.5446 103.254 61.1074 101.691C62.6703 100.128 64.7899 99.25 67 99.25C69.2101 99.25 71.3298 100.128 72.8926 101.691C74.4554 103.254 75.3333 105.373 75.3333 107.583Z"
        fill={color}
        scaleX={scaleX}
        scaleY={scaleY}
      />
    </>
  )
}
