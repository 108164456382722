import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React from 'react'
import styled from 'styled-components'
import FocusIcon from '../icons/FocusIcon'
import MinusIcon from '../icons/MinusIcon'
import PlusIcon from '../icons/PlusIcon'
import { Tooltip } from 'react-tooltip'
import BookingStatistics from '@/components/shared/map/booking-statistics/BookingStatistics'
import LayerSelector from '@/components/shared/map/layer-selector/LayerSelector'

export const MAP_FOCUS_EVENT = 'MAP_FOCUS-EVENT'
export const focusMapEvent = new CustomEvent('MAP_FOCUS-EVENT')
export const focusMap = () => {
  document.dispatchEvent(focusMapEvent)
}

const Controls: React.FC = () => {
  const { width, height } = useWindowSize()

  // data selectors
  const stage = useMapStore((state) => state.stage)

  const scaleRelativeToPoint = (point, increaseScale) => {
    const scaleBy = 1.25
    const oldScale = stage.scaleX()

    const mousePointTo = {
      x: point.x / oldScale - stage.x() / oldScale,
      y: point.y / oldScale - stage.y() / oldScale
    }

    const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy
    const x = (point.x / newScale - mousePointTo.x) * newScale
    const y = (point.y / newScale - mousePointTo.y) * newScale

    const newPos = { x, y }

    stage.scale({ x: newScale, y: newScale })
    stage.position(newPos)
    stage.batchDraw()
  }

  return (
    <ControlsWrapper>
      <MainControls>
        <ControlButton
          data-tooltip-id="app-tooltip"
          data-tooltip-content="Приблизить"
          onClick={scaleRelativeToPoint.bind(
            null,
            {
              x: width / 2,
              y: height / 2
            },
            true
          )}
        >
          <PlusIcon />
        </ControlButton>
        <ControlButton
          data-tooltip-id="app-tooltip"
          data-tooltip-content="Отдалить"
          onClick={scaleRelativeToPoint.bind(
            null,
            {
              x: width / 2,
              y: height / 2
            },
            false
          )}
        >
          <MinusIcon />
        </ControlButton>
        <ControlButton
          data-tooltip-id="app-tooltip"
          data-tooltip-content="Центрировать"
          onClick={focusMap}
        >
          <FocusIcon color="#1e22aa" size={24} />
        </ControlButton>
      </MainControls>
      <BottomControls>
        <BookingStatistics />
        <LayerSelector />
      </BottomControls>
    </ControlsWrapper>
  )
}

export default Controls

const BottomControls = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  @media screen and (max-height: 420px) {
    flex-direction: row;
  }
`

const MainControls = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  flex-grow: 1;

  @media screen and (max-height: 420px) {
    gap: 8px;
  }
`

const ControlsWrapper = styled.div`
  position: fixed;
  top: 75px;
  bottom: 75px;
  right: 16px;
  height: 100%;
  height: calc(var(--app-height) - 150px);

  display: flex;

  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  @media screen and (min-width: 1024px) {
    bottom: 0;
    height: calc(var(--app-height) - 75px);
  }
`

const ControlButton = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s;

  background: #ffffff;
  border: 1px solid #fafafa;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  cursor: pointer;

  &:active {
    transform: scale(0.8);
  }
`
