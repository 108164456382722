import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import { Field, Formik } from 'formik'
import React from 'react'
import styled from 'styled-components'
import Grid from '@/ui/components/Grid'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import { FormControls } from '@/components/ui/form/FormControls'
import { useMaintenanceDialogStore } from './MaintenanceModal'
import { InputField } from '@/ui/components/Field/Input'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import { useMutation } from 'react-query'
import { MailService } from '@/api/services/mail.service'
import { useToast } from '@/components/shared/toast/useToast'
import { useUserStore } from '@/stores/userStore'
import { SelectField } from '@/ui/components/Field/Select'
import { useMaintenance } from '@/components/Maintenance/lib/use-maintenance'
import { createMailParams } from './lib/create-mail-params'

const MaintenanceForm: React.FC = () => {
  const intl = useIntl()
  const user = useUserStore((state) => state.user)
  const { place, seat, employee, onClose } = useMaintenanceDialogStore(
    (state) => ({
      place: state.place,
      seat: state.seat,
      employee: state.employee,
      onClose: state.onClose
    })
  )

  const { options, maintenanceWebURL, maintenanceURL } = useMaintenance()
  const { enqueueToast } = useToast()

  const { mutate } = useMutation(
    'maintenance-user',
    (data: ReturnType<typeof createMailParams>) =>
      MailService.createMaintenance(data),
    {
      onSuccess(data) {
        onClose()
        enqueueToast('Отправлено', { variant: 'success' })
      },
      onError() {
        enqueueToast('Не удалось отправить', { variant: 'error' })
      }
    }
  )
  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const [_, email] = values.type.split(':dl:') || ''
    const reason = options.find((opt) => opt.value == values.type)?.label
    const mailParams = createMailParams({
      reason: reason || 'Обслуживание',
      messageParams: {
        url: maintenanceURL,
        webUrl: maintenanceWebURL,
        place: place || '',
        seat: values.seat,
        created: user?.display || '',
        reason,
        message: values.more
      },
      recipient: email
    })

    mutate(mailParams)
    setSubmitting(false)
  }

  return (
    <Formik
      onSubmit={handleFormSubmit}
      enableReinitialize
      initialValues={{
        seat,
        employee,
        type: options?.at(0)?.value
      }}
    >
      {({ handleSubmit, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('place')}</FormLabel>
                <Field
                  name="seat"
                  $fullWidth
                  required={true}
                  placeholder={intl.formatMessage({ id: 'place' })}
                  component={InputField}
                  disabled={true}
                  style={{ opacity: 0.5 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('employee')}</FormLabel>
                <Field
                  name="employee"
                  $fullWidth
                  required={true}
                  placeholder={intl.formatMessage({ id: 'employee' })}
                  component={InputField}
                  disabled={true}
                  style={{ opacity: 0.5 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormLabel>{translate('service-type')}</FormLabel>
                <Field
                  name="type"
                  $fullWidth
                  required={true}
                  component={SelectField}
                  options={options}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormLabel>{translate('more-info')}</FormLabel>
                <Field
                  name="more"
                  $fullWidth
                  placeholder={intl.formatMessage({ id: 'describe' })}
                  component={InputField}
                  textarea
                  rows={10}
                />
              </Grid>
            </Grid>
          </FormContainer>

          <ControlsWrapper>
            <FormControls>
              <PrimaryButton $fullWidth type="submit" disabled={isSubmitting}>
                {translate('send')}
              </PrimaryButton>
              <DefaultButton $fullWidth type="button" onClick={onClose}>
                {translate('cancel')}
              </DefaultButton>
            </FormControls>
          </ControlsWrapper>
        </form>
      )}
    </Formik>
  )
}

const FormContainer = styled.div`
  padding: 1rem 0;
  max-height: 100%;
`

export default MaintenanceForm

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
