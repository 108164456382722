import { BASE_DOMAIN } from '@/api'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useTree } from '@/api/hooks/useTree'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { checkHidden } from '@/components/layout/Sidebar/Layers/TreeItem'
import MapStage from '@/components/shared/map/stage/MapStage'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import { addMinutes } from 'date-fns'
import { useEffect, useMemo } from 'react'
import PointsLayer from '../point/PointsLayer'
import PolygonsLayer from '../polygon/PolygonsLayer'
import Tooltip from '../tooltip/Tooltip'
import MapCoverLayer from './MapCoverLayer'

const dispatchLoaded = () => {
  const event = new CustomEvent('map-loaded')
  document.dispatchEvent(event)
}

const MapContainer = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer)
  const selection = useGlobalStore((state) => state.selection)
  const nodes = useProjectStore((state) => state.nodes)
  const setMapLayer = useMapStore((state) => state.setMapLayer)
  const role = useUserStore((state) => state.role)

  const node = nodes.find((n) => n.id == activeLayer)
  const hasOwnView = node?.ownView

  const layer = hasOwnView ? node.id : node?.parent || activeLayer

  const { layerView, isSuccess: layerViewLoading } = useLayerView(Number(layer))
  const { layers } = useTree()
  const { metadata } = useMetadata()

  const { data: userFields } = useUserCardFields()
  const apiUrl = useSettingsSelector(
    (settings) => settings.api.url,
    BASE_DOMAIN
  )
  const colors = useSettingsSelector((settings) => settings.colors, [])
  const hideLayers = useSettingsSelector(
    (settings) => settings.hideLayers,
    false
  )

  const sourceType = useSettingsSelector(
    (settings) => settings.map.images,
    'anonymous'
  )
  const scheme = useSettingsSelector((settings) => settings.map.scheme, [])

  const { data, isSuccess } = useBookingsForLayer(
    Number(layer),
    formatLocalDateToAPI(selection.startDate),
    formatLocalDateToAPI(addMinutes(selection.endDate, -30))
  )

  const userDepartmentFieldId = useMemo(
    () =>
      userFields?.fields.find((field) => field.label === '#department')?.uid ||
      null,
    [userFields]
  )

  const userFulltimeFieldId = useMemo(
    () =>
      userFields?.fields.find((field) => field.alias === 'fulltime')?.uid ||
      null,
    [userFields]
  )

  useEffect(() => {
    setMapLayer(Number(layer))
  }, [layer, activeLayer])

  useEffect(() => {
    if (isSuccess && layerViewLoading) {
      dispatchLoaded()
    }
  }, [isSuccess, layerViewLoading, activeLayer])

  const accessiblePolygons = useMemo(
    () =>
      layerView?.polygons.filter((polygon) => {
        if (!hideLayers) return true
        const curr = layers?.nodes.find((n) => n.id == polygon.id)
        if (!curr) return true

        const isHidden = checkHidden(curr, metadata?.layers)
        return role === RolesEnum.Admin ? true : !isHidden
      }),
    [layers?.nodes, layerView?.polygons, metadata?.layers]
  )

  return (
    <MapStage>
      <MapCoverLayer
        view={layerView?.view}
        url={apiUrl}
        sourceType={sourceType}
      />
      <PolygonsLayer polygons={accessiblePolygons} />
      <PointsLayer
        userDepartmentFieldId={userDepartmentFieldId}
        userFulltimeFieldId={userFulltimeFieldId}
        colors={colors || {}}
        nodes={metadata?.rawNodes}
        points={layerView?.points}
        options={layerView?.options}
        bookings={data?.bookings}
        url={apiUrl}
        sourceType={sourceType}
        scheme={scheme}
      />
      <Tooltip />
    </MapStage>
  )
}

MapContainer.whyDidYouRender = true

export default MapContainer
