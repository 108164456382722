import { useMemo } from 'react'

export const useIconSize = (size: number, pointStrokeWidth: number) => {
  return useMemo(() => {
    const iconSize = size * 0.4
    const imageSize = iconSize * 0.6
    const strokeWidth = pointStrokeWidth * 0.4
    const padding = (iconSize - imageSize) / 2
    return { iconSize, imageSize, padding, strokeWidth }
  }, [size])
}
