import { BasicPointProps } from '@/components/shared/map/point/basic/basic-point.interface'
import { API } from '@/api'
import {
  defaultMapPointImage,
  getImageLink
} from '@/components/shared/map/point/Point'
import useImage from 'use-image'
import { useEffect, useState } from 'react'
import fallbackAvatar from '@/images/man.svg'

export const useMapAvatar = (
  bookingData: BasicPointProps['bookingData'],
  node: BasicPointProps['node'],
  url: string,
  sourceType: 'anonymous' | 'use-credentials'
) => {
  const [imgSrc, setImgSrc] = useState('')
  const [pointImg, status] = useImage(imgSrc, sourceType)

  useEffect(() => {
    if (bookingData) {
      setImgSrc(
        API.user.getAvatar({
          userId: Number(bookingData.user_id),
          url
        })
      )
    } else {
      setImgSrc(
        getImageLink(node?.icon, url) || getImageLink(defaultMapPointImage, url)
      )
    }
  }, [bookingData])

  useEffect(() => {
    if (status === 'failed') {
      setImgSrc(fallbackAvatar)
    }
  }, [status])

  return { image: pointImg }
}
