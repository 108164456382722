export const getBookingType = ({
  rec,
  end
}: {
  rec: string
  end?: Date | string
}) => (rec != '0' ? 'recurrent' : end ? 'common' : 'constant')

type BookingData = {
  recurrence: '0' | '1'
  end: string | null
}

export const isBookingPermanent = (data: BookingData) => {
  const { recurrence, end } = data
  if (recurrence !== '0') return false
  return !end
}
