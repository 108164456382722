import React from 'react'

const MapPointIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1 11.2C12.9837 11.2 13.7 10.4837 13.7 9.6C13.7 8.71634 12.9837 8 12.1 8C11.2163 8 10.5 8.71634 10.5 9.6C10.5 10.4837 11.2163 11.2 12.1 11.2ZM12.1 13.2C14.0882 13.2 15.7 11.5882 15.7 9.6C15.7 7.61177 14.0882 6 12.1 6C10.1118 6 8.5 7.61177 8.5 9.6C8.5 11.5882 10.1118 13.2 12.1 13.2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8253 12.4158C19.4358 11.2012 19.7 10.2539 19.7 9.6C19.7 5.40264 16.2974 2 12.1 2C7.90264 2 4.5 5.40264 4.5 9.6C4.5 10.2539 4.76421 11.2012 5.37466 12.4158C5.96812 13.5965 6.80406 14.865 7.74318 16.1146C9.25227 18.1225 10.9423 19.9693 12.1 21.1699C13.2577 19.9693 14.9477 18.1225 16.4568 16.1146C17.3959 14.865 18.2319 13.5965 18.8253 12.4158ZM12.7736 23.3392C14.9571 21.1532 21.7 14.0435 21.7 9.6C21.7 4.29807 17.4019 0 12.1 0C6.79807 0 2.5 4.29807 2.5 9.6C2.5 14.0435 9.24286 21.1532 11.4264 23.3392C11.802 23.7151 12.398 23.7151 12.7736 23.3392Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default MapPointIcon
