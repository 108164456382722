import React from 'react'

const PointIcon = ({ width = 27, height = 26 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0625 15.1665C15.4524 15.1665 17.3959 13.223 17.3959 10.8332C17.3959 8.44338 15.4524 6.49988 13.0625 6.49988C10.6727 6.49988 8.72919 8.44338 8.72919 10.8332C8.72919 13.223 10.6727 15.1665 13.0625 15.1665ZM13.0625 8.66654C14.2574 8.66654 15.2292 9.63829 15.2292 10.8332C15.2292 12.0281 14.2574 12.9999 13.0625 12.9999C11.8676 12.9999 10.8959 12.0281 10.8959 10.8332C10.8959 9.63829 11.8676 8.66654 13.0625 8.66654Z" fill="#1e22aa" />
            <path d="M12.4342 23.6317C12.6175 23.7626 12.8372 23.833 13.0625 23.833C13.2878 23.833 13.5075 23.7626 13.6908 23.6317C14.0202 23.3988 21.7606 17.8098 21.7292 10.8332C21.7292 6.05459 17.8411 2.1665 13.0625 2.1665C8.28393 2.1665 4.39585 6.05459 4.39585 10.8278C4.36443 17.8098 12.1048 23.3988 12.4342 23.6317ZM13.0625 4.33317C16.6473 4.33317 19.5625 7.24842 19.5625 10.8386C19.5853 15.6464 14.8088 19.9635 13.0625 21.3794C11.3173 19.9624 6.53976 15.6443 6.56251 10.8332C6.56251 7.24842 9.47776 4.33317 13.0625 4.33317Z" fill="#1e22aa" />
        </svg>
    )
}

export default PointIcon