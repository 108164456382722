import { BasicPointProps } from '@/components/shared/map/point/basic/basic-point.interface'
import { useCallback } from 'react'
import { cancelPoygonEvent } from '@/components/shared/map/polygon/Polygon'

export const useEventHandlers = (
  isSchemeObject: boolean,
  setSeat: (id: number) => void,
  setSeatEmployee: (id: number | null) => void,
  bookingData: BasicPointProps['bookingData'],
  pointId: number,
  name: string,
  setTooltip: (tooltip: string | null) => void
) => {
  const onSelect = useCallback(() => {
    if (isSchemeObject) return
    setSeat(pointId)
    document.dispatchEvent(cancelPoygonEvent)

    if (bookingData) {
      setSeatEmployee(Number(bookingData.user_id))
    } else {
      setSeatEmployee(null)
    }
  }, [setSeat, setSeatEmployee, bookingData, pointId, isSchemeObject])

  const onMouseEnterHandler = useCallback(
    (e: any) => {
      if (isSchemeObject) return
      const container = e.target.getStage()?.container()
      if (container) container.style.cursor = 'pointer'
    },
    [isSchemeObject]
  )

  const onMouseLeaveHandler = useCallback(
    (e: any) => {
      if (isSchemeObject) return
      const container = e.target.getStage()?.container()
      if (container) container.style.cursor = 'default'
    },
    [isSchemeObject]
  )

  const onGroupMouseEnterHandler = useCallback(() => {
    setTooltip(name)
  }, [setTooltip, name])

  const onGroupMouseLeaveHandler = useCallback(() => {
    setTooltip(null)
  }, [setTooltip])

  return {
    onSelect,
    onMouseEnterHandler,
    onMouseLeaveHandler,
    onGroupMouseEnterHandler,
    onGroupMouseLeaveHandler
  }
}
