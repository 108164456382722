const BookingStatisticsIcon = () => {
  return (
    <svg
      width="498"
      height="423"
      viewBox="0 0 498 423"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H11.3182V411.644H498V423H0V0Z"
        fill="white"
      />
      <path d="M28.5591 278.544H153.059V394.94H28.5591V278.544Z" fill="white" />
      <path d="M172.866 142.276H297.366V394.94H172.866V142.276Z" fill="white" />
      <path d="M317.173 25.8797H444.502V394.94H317.173V25.8797Z" fill="white" />
    </svg>
  )
}

export default BookingStatisticsIcon
