import ModalService from '@/components/shared/modal/ModalService'
import styled from 'styled-components'
import LayerIcon from '@/components/shared/map/layer-selector/LayerIcon'
import media from '@/ui/media'
import LayerSelectorModal from '@/components/shared/map/layer-selector/LayerSelectorModal'
import { AnimatePresence, motion } from 'framer-motion'
import { useMapStore } from '@/stores/mapStore'
import { Tooltip } from 'react-tooltip'

const LayerSelector = () => {
  const option = useMapStore((state) => state.option)
  const setOption = useMapStore((state) => state.setOption)

  const handleLayerSelector = () => {
    ModalService.open(LayerSelectorModal, { option, setOption })
  }

  return (
    <LayerSelectorWrapper>
      <AnimatePresence>
        {option === 'load' && (
          <LayersLoad
            key="box"
            initial={{ y: '50%', opacity: 0, scale: 0.5 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: '50%', opacity: 0, transition: { duration: 0.1 } }}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            <Caption>Нагрузка на место</Caption>
            <Loaded>Более 70%</Loaded>
            <Moderate>30 - 70%</Moderate>
            <Light>Менее 30%</Light>
            <NotBookable>Небронируемые</NotBookable>
          </LayersLoad>
        )}
      </AnimatePresence>

      <LayerSelectorButton
        data-tooltip-id="app-tooltip"
        data-tooltip-content="Слои на карте"
        onClick={handleLayerSelector}
      >
        <LayerIcon />
      </LayerSelectorButton>
    </LayerSelectorWrapper>
  )
}

const Typography = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  text-align: right;
  color: #2c2c2c;
  padding: 0 8px;

  &:not(:last-child) {
    margin-bottom: 1px;
  }
`

const Caption = styled(Typography)``

const Loaded = styled(Typography)`
  background: #ae0000;
  color: #fff;
`

const Moderate = styled(Typography)`
  background: #f3bb5e;
`

const Light = styled(Typography)`
  background: #1e22aa;
  color: #fff;
`

const NotBookable = styled(Typography)`
  background: #d9d9d9;
`

const LayersLoad = styled(motion.div)`
  background: #fff;
  border: 1px solid #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;

  min-width: 160px;
`

const LayerSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-height: 420px) {
    flex-direction: row;
  }
`

const LayerSelectorButton = styled.div`
  cursor: pointer;
  background: #1e22aa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media screen and (max-height: 420px) {
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  ${media.md`
    bottom: 10rem;
  `}
`

export default LayerSelector
