import media from '@/ui/media'
import styled from 'styled-components'
import BookingStatisticsPopup from './BookingStatisticsPopup'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { easeQuadOut } from 'd3-ease'
import BookingStatisticsIcon from './BookingStatisticsIcon'
import {
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating
} from '@floating-ui/react'

const BookingStatistics = () => {
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(!isOpen)
  const close = () => setOpen(false)

  let { refs, floatingStyles } = useFloating({
    placement: 'top-end',
    middleware: [
      flip({ fallbackPlacements: ['bottom-end'] }),
      offset(8),
      shift({ padding: 8 })
    ]
  })

  return (
    <BookingStatisticsWrapper>
      <AnimatePresence>
        {isOpen && (
          <FloatingPortal>
            <motion.div
              key="modal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: easeQuadOut }}
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex: 22022 }}
            >
              <BookingStatisticsPopup close={close} />
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>

      <BookingStatisticsButton
        data-tooltip-id="app-tooltip"
        data-tooltip-content="Статистика"
        onClick={toggle}
        ref={refs.setReference}
      >
        <BookingStatisticsIcon />
      </BookingStatisticsButton>
    </BookingStatisticsWrapper>
  )
}

const BookingStatisticsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  ${media.lg`
    bottom: 18rem;
  `}
`

const BookingStatisticsButton = styled.div`
  cursor: pointer;
  background: #1e22aa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;

  @media screen and (max-height: 420px) {
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`

export default BookingStatistics
