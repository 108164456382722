import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { BookingService } from '../services/booking.service';
import { useBookings } from './useBookings';
import { DeepBookingItem, DeepBookingService } from '../services/deep.booking.service';
import { RuntimeFieldData, UserDataListDto, UserService } from '../services/user.service';

export type MomentType = 'all' | 'past' | 'current' | 'future' | 'recurrent' | 'constant' | 'my'

export type DeepBookingsQuery = {
    page: number
    perPage?: number
    moment?: MomentType
    user?: UserDataListDto
    placeType?: string
	place?: string
	bookingType?: string
    sort?: string
    direction?: 0 | 1
    day?: Date
    start?: Date
	end?: Date
    my?: string
}

type UseDeepBookingsProps = {
    query: DeepBookingsQuery
    userRuntimeFields?: "uid" | "alias"
}

export const useDeepBookings = ({
    query: { page, perPage, moment, bookingType, placeType, user, sort, direction, day, start, end, place, my },
    userRuntimeFields
}: UseDeepBookingsProps) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['deep-bookings', workspaceId, projectId, page, moment, bookingType, placeType, user, sort, direction, day, start, end, place],
        async () => {
            const getRuntimeFields = getRuntimeFieldsFactory(workspaceId, projectId)
            const withRuntimeFields = withRuntimeFieldsFactory(getRuntimeFields, userRuntimeFields)
            const preparedUser = await withRuntimeFields(user) 
            const { data: { items, ...restData }, ...restResponse } = await DeepBookingService.getBookingList({ 
                workspaceId, projectId, page: page - 1, perPage, moment, 
                bookingType, placeType, user: preparedUser, sort, direction, day, start, end, place, my 
            })
            const asyncPreparedResult = items.map(async ({ user, ...rest }) => ({ ...rest, user: await withRuntimeFields(user) }))
            const preparedResult: DeepBookingItem[] = await Promise.all(asyncPreparedResult)
            return { ...restResponse, data: { ...restData, items: preparedResult } }
        },
        {
            enabled: !!workspaceId && !!projectId && !!page,
            select: ({ data }) => ({ items: data.items, total: data.total }),
            keepPreviousData: true,
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить список бронирований' }, { variant: 'error' })
            }
        }
    )

    return { data, isLoading, refetch }
}

const getRuntimeFieldsFactory = (workspaceId: number, projectId: number) => {
    let cache: RuntimeFieldData[] = []
    return async () => {
        if (cache.length) return cache
        const runtimeFieldsResponse = await UserService.getRuntimeFields({ workspaceId, projectId })
        cache = runtimeFieldsResponse.data.required_fields
        return cache
    }
}

const withRuntimeFieldsFactory = 
    (getRuntimeFields: () => Promise<RuntimeFieldData[]> , userRuntimeFields: "uid" | "alias" = "uid") => 
        async <T extends Record<string, unknown> | undefined> (user: T) => {
            if (!user) return user;
            const fields: Record<string, string> = userRuntimeFields === "alias" 
                ? (await getRuntimeFields())
                    .reduce((result, field) => ({...result, [field.uid]: field[userRuntimeFields] }), {})
                : {} 
            const entries = Object.entries(user).map(([key, value]) => [fields[key] ?? key, value])
            return Object.fromEntries(entries) as T
        }