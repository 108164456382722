import Grid from '@/ui/components/Grid'
import React from 'react'
import styled, { css } from 'styled-components'
import WeekSelector from './WeekSelector'
import { useFormikContext } from 'formik'
import GridAnnotation from './GridAnnotation'
import GridCalendar from './calendar-selector/GridCalendar'
import useResponsive from '@/hooks/useResponsive'

export type BookingSlot = {
  start: Date
  end: Date
}

type BookingGridProps = {
  bookings: any
  addItem: Function
}

const BookingGridSelector: React.FC<BookingGridProps> = ({ addItem }) => {
  const { isMobile } = useResponsive()
  const { values } = useFormikContext<any>()

  return (
    <>
      <WeekSelector visible={isMobile || values.type !== '2'} />

      <CssGrid $full={isMobile}>
        {!isMobile && (
          <GridCols>
            <div />
            {Array.from({ length: 24 }).map((item, idx) => (
              <GridTime key={idx}>{`${idx}`.padStart(2, '0')}:00</GridTime>
            ))}
          </GridCols>
        )}

        <GridCalendar addItem={addItem} />

        <Grid container>
          <Grid item xs={6} md={3}>
            <GridAnnotation color="#FD4F26" text="already-booked" />
          </Grid>
          <Grid item xs={6} md={3}>
            <GridAnnotation color="#F3BB5E" text="booked-by-me" />
          </Grid>
          <Grid item xs={6} md={3}>
            <GridAnnotation color="#c795f1" text="my-parallel-bookings" />
          </Grid>
          <Grid item xs={6} md={3}>
            <GridAnnotation color="#1e22aa" text="current-booking" />
          </Grid>
          <Grid item xs={6} md={3}>
            <GridAnnotation color="#BFBFBF" text="free" />
          </Grid>
        </Grid>
      </CssGrid>
    </>
  )
}

export default BookingGridSelector

const CssGrid = styled.div<{ $full?: boolean }>`
  display: grid;
  row-gap: 6px;

  ${({ $full }) =>
    $full
      ? css`
          grid-template-rows: 1fr;
          margin-top: 20px;
        `
      : css`
          grid-template-rows: 42px 1fr;
        `}
`

const GridCols = styled.div`
  display: grid;
  grid-template-columns: 24px repeat(24, 1fr);
`
const GridTime = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  color: #000000;
  display: inline-block;
  white-space: nowrap;
  transform: translate(0, 100%) rotate(-90deg);
  transform-origin: top left;
`
