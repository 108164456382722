import { MomentType } from '@/api/hooks/useBookings'
import { ExtraUserData, UserDataListDto, UserListDto, UserService } from './user.service'
import { BookingItem, BookingService } from './booking.service'
import { ApiResponseType } from '@/api'
import { AxiosResponse } from 'axios'

const SUB_ENTITY_PER_PAGE = 1000

export const DeepBookingService = {
    /**
     * Список бронирований с полной информацией о пользователях
     * 
     * @todo Добавить полную информацию о местах
     */
	async getBookingList({ workspaceId, projectId, user, ...bookingsProps }: DeepBookingListDto) {
		const response = await BookingService.getBookingList({workspaceId, projectId, ...bookingsProps})
        const { data: { items, ...restData }, ...restResponse } = response;
        const userIds = items.map(({ user_id }) => user_id)
        const users = await getUsers(userIds, { ...user, workspaceId, projectId });                
        const userById = findByProp(users, "id")
        const withSubEntities = items
            .map(({ user_id, ...data}) => ({ ...data, user: userById(user_id) }))   
            .filter(({ user }) => !!user)     
        return { ...restResponse, data: { ...restData, items: withSubEntities } } as AxiosResponse<DeepBookingListResponse>
	},
}

const findByProp = <T, P extends keyof T>(items: T[], prop: P) => (value: T[P]) => {    
    return items.find((item) => item[prop] == value)
}

const getUsers = async (ids: string[], filters: UserListDto, page = 0): Promise<ExtraUserData[]> => {
    const perPage = SUB_ENTITY_PER_PAGE
	const { data: {items, total} } = await UserService.getUsersList({ ...filters, page, perPage })
    const hasNext = total > (page + 1) * perPage
    const nextItems = hasNext ? await getUsers(ids, filters, page + 1) : []
    return items
        .filter(({ id }) => ids.indexOf(id) != -1)
        .concat(nextItems)
}

export interface DeepBookingListDto {
	workspaceId: number
	projectId: number
	page?: number
	perPage?: number
	user?: UserDataListDto
	placeType?: string
	place?: string
	bookingType?: string
	moment?: MomentType
	sort?: string
	direction?: 0 | 1
	day?: Date
	start?: Date
	end?: Date
	my?: string
}

export type DeepBookingItem = Omit<BookingItem, "user" | "user_id"> & { user: ExtraUserData }

export interface DeepBookingListResponse extends ApiResponseType {
    items: DeepBookingItem[]
	total: number
}