import { css, createGlobalStyle } from 'styled-components'

import ProximaNovaLight from '../../assets/fonts/proxima-nova/proximanova_light.woff2'
import ProximaNovaRegular from '../../assets/fonts/proxima-nova/proximanova_regular.woff2'
import ProximaNovaBold from '../../assets/fonts/proxima-nova/proximanova_bold.woff2'
import ProximaNovaMedium from '../../assets/fonts/proxima-nova/proximanova_medium.woff2'
import ProximaNovaSemibold from '../../assets/fonts/proxima-nova/proximanova_semibold.woff2'

export const globalStyles = css`
  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    src: url('${ProximaNovaLight}') format('woff2');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url('${ProximaNovaRegular}') format('woff2');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    src: url('${ProximaNovaMedium}') format('woff2');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    src: url('${ProximaNovaSemibold}') format('woff2');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    src: url('${ProximaNovaBold}') format('woff2');
  }

  :root {
    --app-height: 100%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * {
    min-width: 0;
    min-height: 0;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body,
  #root {
    width: 100vw;
    /* min-width: 100vh; */
    height: 100vh;
    overflow: hidden;
  }

  #root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  body {
    font-family: 'Proxima Nova', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    width: 100vw;
    height: 100vh;
    font-size: 1.6rem;
    overflow: hidden;
  }

  html,
  body {
    overscroll-behavior-y: none;
  }
  body {
    position: fixed;
    overflow: hidden;
  }

  html,
  body,
  #root,
  #modals {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
  }
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`

export default globalStyles