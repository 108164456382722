import { sizes } from '@/ui/media'
import { DefaultTheme } from 'styled-components'

export const blueTheme: DefaultTheme = {
    grid: {
        gutter: 8,
        breakpoints: sizes
    },
    palette: {
        default: {
            main: '#fff',
            text: '#2C2C2C'
        },
        accent: {
            main: '#1e22aa',
        },
        primary: {
            main: '#FFFFFF',
        },
        background: {
            primary: '#FFFFFF',
            default: '#fff',
        },
        text: {
            primary: "#000",
            secondary: "#000",
            label: '#2B36BA',
            input: 'rgba(0, 0, 0, 0.25)',
        }
    },
}

export default blueTheme