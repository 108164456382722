import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { useGlobalStore } from '@/stores/globalStore';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { StatusResponseEnum } from '..';
import { ProjectService } from '../services/project.service';

export const useMetadata = () => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()
    const intl = useIntl()
    const navigate = useNavigate()

    const reset = useGlobalStore(store => store.reset)


    const { data, isLoading, refetch } = useQuery({
        queryKey: ['metadata', workspaceId, projectId],
        queryFn: () => ProjectService.getMetadata({ workspaceId, projectId }),
        enabled: !!workspaceId && !!projectId && Number(workspaceId) > 0 && Number(projectId) > 0,
        select: ({ data }) => {
            try {
                const nodes = data?.metablock?.nodes ? Object.values(data?.metablock?.nodes) : []
                return {
                    data,
                    status: data.status,
                    rawNodes: data?.metablock.nodes,
                    nodes,
                    layers: data.metablock.layers
                }
            } catch (e) {
                return {
                    data,
                    status: data.status,
                    rawNodes: {},
                    nodes: [],
                    layers: {}
                }
            }

        },
        onError: () => {
            enqueueToast({ title: intl.formatMessage({ id: 'error' }), message: intl.formatMessage({ id: 'error-load-metadata' }) }, { variant: 'error' })
        }
    })


    if (data?.['status'] === StatusResponseEnum.NotAuthorized) {
        reset()
        navigate(`/login?workspace_id=${workspaceId}&project_id=${projectId}`)
    }

    return { metadata: data, data, isLoading, refetch }
}