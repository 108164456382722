import Modal from '@/components/Modal/Modal';
import Pagination from '@/ui/components/Pagination/Pagination';
import media from '@/ui/media';
import React, { useState } from 'react';
import styled from 'styled-components'
import useResponsive from '@/hooks/useResponsive';
import { useIntl } from 'react-intl';
import { translate } from '@/i18n';
import { Export } from './Export';
import { DeepBookingsQuery, useDeepBookings } from '@/api/hooks/useDeepBookings';
import { TableItem } from './TableItem';
import { NoResults } from './NoResults';
import { BookingTableColumn } from './BookingTableColumn';
import { ReportToolbar } from './ReportToolbar';
import { addMinutes, endOfWeek, startOfWeek } from 'date-fns';

type ReportModalProps = {
    report: string | null
    item: {
        name: string
        key: string
    }
    onClose: () => void
}


const AbstractBookingsReport = (columns: BookingTableColumn[]): React.FC<ReportModalProps> => ({ report, item, onClose }) => {
    const [query, setQuery] = useState<DeepBookingsQuery>({ 
        page: 1, 
        perPage: 1000,
        start: startOfWeek(new Date()),
        end: endOfWeek(addMinutes(new Date(), -30)),
    })
    const { isDesktop } = useResponsive()
    const { data } = useDeepBookings({ query, userRuntimeFields: "alias" })
    const intl = useIntl()
    
    const headers = columns.map((item) => ({ ...item, label: intl.formatMessage({ id: item.alias }) }))
    
    return (
        <Modal isOpen={!!report} onClose={onClose}>
            <Header>
                <Title>{translate('report-template', { name: intl.formatMessage({ id: item.key }) })}</Title>
                <Export report={report} onClose={onClose} headers={headers} items={data?.items ?? []} ></Export>
            </Header>

            <ReportToolbar columns={columns} items={data?.items ?? []} query={query} setQuery={setQuery} />

            <Table>
                {isDesktop && (
                    <TableHead>
                        <tr>
                            {headers.map(header => (
                                <TableHeadCell key={header.key}>
                                    {header.label}
                                </TableHeadCell>
                            ))}
                        </tr>
                    </TableHead>
                )}

                <TableBody>
                    { (data?.items ?? []).map(item => <TableItem key={item.id} item={item} columns={columns} />) }
                    { !data?.items.length && <NoResults />}
                </TableBody>

            </Table>

            <Pagination
					inverse
					currentPage={query.page}
					itemsPerPage={query.perPage}
					total={data?.total || 0}
					handlePageChange={(page: number) => setQuery({ ...query, page })}
				/>
        </Modal>
    )
}

export default AbstractBookingsReport

const TableHeadCell = styled.th`
    text-align: left;
    background: #efefef;
	border: 1px solid #dddddd;
    padding: 5px;
`

const TableBody = styled.tbody``

const TableHead = styled.thead`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
`

const Table = styled.table`
    margin: 1rem 0;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    overflow-x: hidden;


tr td:last-child {
    width: 1%;
    white-space: nowrap;
}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`



const Title = styled.div`
font-weight: 500;
font-size: 2.4rem;
line-height: 2.4rem;
color: #000000;
`