import { FormLabel } from '@/ui/components/Form/FormLabel';
import Grid from '@/ui/components/Grid';
import React, { useEffect } from 'react';
import styled from 'styled-components'
import { Field, useFormikContext } from 'formik';
import { DatePickerField } from '@/ui/components/Field/DatePickerField';
import PlusIcon from '@/components/icons/PlusIcon';
import { getHours, getMinutes, setHours, setMinutes, isAfter, isToday, isSameDay, addMinutes, isEqual } from 'date-fns';
import { translate } from '@/i18n';

const WeeklyInterval = () => {
    const { setFieldValue, values } = useFormikContext<any>()
    
    const dates = values.weekly
    const interval = dates ? dates : { start: "", end: "" }

    const start = interval.start ? addMinutes(interval.start, 30) : null

    const isItSame = interval.end ? isSameDay(interval.start, interval.end) : true
    const minHours = start ? getHours(start) : 0
    const minMinutes = start ? getMinutes(start) : 0
    const minTime = isItSame ? setHours(setMinutes(new Date(), minMinutes), minHours) : setHours(setMinutes(new Date(), 0), 0)

    return (
        <Grid container>
            <Grid item xs={6} md={4}>
                <FormLabel>{translate('start-working')}</FormLabel>
                <Field
                    placeholder="Время начала"
                    name={`weekly.start`}
                    component={DatePickerField}
                    $fullWidth
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    onChange={val => {
                        const isIntervalAfter = interval.end && val ? isAfter(val, interval.end) : false
                        const equality = interval.end && val ? isEqual(val, interval.end) : false

                        if (isIntervalAfter || equality) {
                            setFieldValue(`weekly.end`, addMinutes(val, 30))
                        }

                        setFieldValue(`weekly.start`, val)

                    }}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <FormLabel>{translate('end-working')}</FormLabel>
                <Field
                    $fullWidth
                    placeholder="Время окончания"
                    name={`weekly.end`}
                    // selected={start}
                    showTimeSelectOnly
                    dateFormat="HH:mm"
                    openToDate={start}
                    minDate={start || null}
                    minTime={minTime}
                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    component={DatePickerField}
                    onChange={val => {
                        const isIntervalAfter = interval.start && val ? isAfter(val, interval.start) : false
                        const equality = interval.start && val ? isEqual(val, interval.start) : false

                        if (equality || !isIntervalAfter) {
                            setFieldValue(`weekly.end`, addMinutes(interval.start, 30));
                        } else {
                            setFieldValue(`weekly.end`, val);
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export const NoBookingIntervals = ({ helpers }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <AddButton type="button" onClick={() => helpers.push({ start: '', end: '' })}>
                    <BtnWrapper as="div">
                        <PlusIcon color="#fff" />
                    </BtnWrapper>
                    <BtnText>{translate('add-interval')}</BtnText>
                </AddButton>
            </Grid>
        </Grid>
    )
}

export default WeeklyInterval

const Buttons = styled.div`
    display: flex;
    margin-top: 34px;
    justify-content: space-around;
    /* align-items: center; */
`

const BtnWrapper = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    background: #1e22aa;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &:hover {
        opacity: 0.7;
    }
`

const BtnText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    margin-left: 12px;
    cursor: pointer;
`

const AddButton = styled.button`
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
`