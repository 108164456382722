import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import fallbackAvatar from '@/images/man.svg'

const AvatarWrapper = styled.div<{ $size?: string }>`
  border: 1px solid #ffffff;
  background-color: #fff;

  ${({ $size }) => {
    switch ($size) {
      case 'large': {
        return css`
          width: 100px;
          height: 100px;
        `
      }
      case 'big': {
        return css`
          width: 60px;
          height: 60px;
        `
      }
      default: {
        return css`
          width: 40px;
          height: 40px;
        `
      }
    }
  }}

  border-radius: 50%;
  overflow: hidden;
`

const StyledImg = styled.img<{ $size?: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Avatar = ({ src, size = 'medium', fallback = fallbackAvatar }) => {
  const [imageSrc, setImageSrc] = useState(src)

  const handleImageError = () => {
    setImageSrc(fallback)
  }

  return (
    <AvatarWrapper $size={size}>
      <StyledImg src={imageSrc} onError={handleImageError} alt="Avatar" />
    </AvatarWrapper>
  )
}

export default Avatar
