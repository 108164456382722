import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { useProject } from '@/hooks/useProject'
import { getMaintenanceOptions } from './get-maintenance-options'

export const useMaintenance = () => {
  const project = useProject()

  const maintenance = useSettingsSelector(
    (settings) => settings.maintenance,
    {}
  )
  const maintenanceURL = useSettingsSelector(
    (settings) => settings.maintenanceURL,
    ''
  )
  const maintenanceWebURL = useSettingsSelector(
    (settings) => settings.maintenanceWebURL,
    ''
  )

  const options = getMaintenanceOptions({ options: maintenance, project })

  return {
    options,
    maintenanceURL,
    maintenanceWebURL
  }
}
