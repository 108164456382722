import { areIntervalsOverlapping, getTime } from 'date-fns'
import isValid from 'date-fns/isValid'

type SlotType = {
  start: Date
  end: Date
}
type IntervalType = SlotType

export const sortSlotsAscending = (a: DateSlotType, b: DateSlotType) =>
  getTime(a.start) - getTime(b.start)

export const isSlotValidPredicate = (slot: SlotType) =>
  isValid(slot.start) && isValid(slot.end)

export type DateSlotType = { start: Date; end: Date }

const getDuration = (slot: DateSlotType) =>
  getTime(slot.end) - getTime(slot.start)

const isSlotIncluded = (slot1: DateSlotType, slot2: DateSlotType) =>
  getTime(slot1.start) <= getTime(slot2.start) &&
  getTime(slot1.end) >= getTime(slot2.end)

export const validateIntervalSlots = (slots: IntervalType[]) => {
  const dateSlots = slots.sort(sortSlotsAscending)

  const valid = new Set()
  let conflicts = dateSlots.concat()
  let iterations = 0

  while (conflicts.length > 0) {
    if (iterations >= 200) break
    const newConflicts: DateSlotType[] = []

    if (conflicts.length === 1 && !valid.has(conflicts.at(0))) {
      valid.add(conflicts.at(0))
      conflicts = []
      break
    }

    const disabled = new Set()

    conflicts.forEach((primary, i) => {
      const primaryDuration = getDuration(primary)
      if (disabled.has(primary)) return

      // overlapping count if 0, so we can add it to valid slots
      let overlappedCount = 0
      let shouldSkip = false

      conflicts.forEach((secondary, j) => {
        if (shouldSkip) return
        if (i === j || i > j) return

        // const isSameDayStart = isSameDay(primary.start, secondary.start)
        const isSameStart = getTime(primary.start) === getTime(secondary.start)

        if (isSameStart) {
          overlappedCount += 1
          const secondaryDuration = getDuration(secondary)
          const isPrimaryLonger = primaryDuration > secondaryDuration

          if (isPrimaryLonger) {
            newConflicts.push(primary)
            disabled.add(secondary)
          } else {
            shouldSkip = true
            return
          }
        }

        const isIncluded = isSlotIncluded(primary, secondary)
        if (isIncluded) {
          disabled.add(secondary)
          return
        }

        const isReplaced = getTime(primary.start) > getTime(secondary.start)
        const primarySlot = isReplaced ? secondary : primary
        const secondarySlot = isReplaced ? primary : secondary

        const isOverlapping = areIntervalsOverlapping(
          primarySlot,
          secondarySlot
        )

        if (isOverlapping) {
          overlappedCount += 1
          newConflicts.push({
            start: primarySlot.start,
            end: secondarySlot.end
          })
          disabled.add(secondary)
          shouldSkip = true
          return
        }
      })

      if (!overlappedCount) {
        const isInNewConflict = newConflicts.some((conf) => {
          const [slot1, slot2] = [conf, primary].sort(sortSlotsAscending) as [
            DateSlotType,
            DateSlotType
          ]

          return areIntervalsOverlapping(slot1, slot2)
        })

        if (isInNewConflict) {
          newConflicts.push(primary)
        } else {
          valid.add(primary)
        }
      }
    })

    const disabledArr = [...disabled]

    disabledArr.forEach((item) => {
      valid.delete(item)
    })

    conflicts = newConflicts.sort(sortSlotsAscending).concat()
    iterations++
  }

  return ([...valid] as DateSlotType[]).sort(sortSlotsAscending)
}
