import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfDay,
  isBefore,
  isEqual,
  isFriday,
  isWeekend,
  isWithinInterval,
  nextMonday,
  set,
  startOfDay
} from 'date-fns'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { useMemo } from 'react'

type IntervalType = {
  from: Date
  to: Date
}

export const getTimeForBooking = (isRestrictedLayer: boolean) => {
  // const currentTime = new Date('2024-09-02T16:23:00')
  const currentTime = set(new Date(), { seconds: 0, milliseconds: 0 })

  // Округляем ближайшее время: 0 или 30 минут
  const roundedTime = set(currentTime, {
    minutes: currentTime.getMinutes() < 30 ? 0 : 30,
    seconds: 0,
    milliseconds: 0
  })

  const intervals: IntervalType[] = []
  const startOfRoundedDay = startOfDay(roundedTime)

  if (isRestrictedLayer) {
    const isWeekendDay = isWeekend(roundedTime) || isFriday(roundedTime)
    const todayInterval = {
      from: roundedTime,
      to: addDays(startOfRoundedDay, 1)
    }

    if (isFriday(roundedTime)) {
      intervals.push(todayInterval)
    }

    if (isWeekendDay) {
      intervals.push({
        from: nextMonday(startOfRoundedDay),
        to: addDays(nextMonday(startOfRoundedDay), 1)
      })
    } else {
      intervals.push(todayInterval)
      intervals.push({
        from: addDays(startOfRoundedDay, 1),
        to: addDays(startOfRoundedDay, 2)
      })
    }
  } else {
    intervals.push({
      from: roundedTime,
      to: addDays(addWeeks(startOfRoundedDay, 1), 1)
    })
  }

  return intervals
}

const getAllIntervalDates = (intervals: IntervalType[]): Date[] => {
  const allDates: Date[] = intervals.flatMap((interval) =>
    eachDayOfInterval({
      start: startOfDay(interval.from),
      end: endOfDay(interval.to)
    })
  )

  return [...new Set(allDates)]
}

const getCurrentIntervalDates = (
  intervals: IntervalType[],
  start?: Date | null
): Date[] => {
  if (!start) return []
  const allDates: Date[] = intervals
    .filter((slot) =>
      isWithinInterval(start, {
        start: slot.from,
        end: slot.to
      })
    )
    .flatMap((interval) =>
      eachDayOfInterval({
        start: startOfDay(interval.from),
        end: endOfDay(interval.to)
      })
    )

  return [...new Set(allDates)]
}

const getStartIntervalDates = (intervals: IntervalType[]): Date[] => {
  const getValidInterval = (interval: IntervalType) => {
    const start = startOfDay(interval.from)
    const end = startOfDay(interval.to)
    // Корректировка конечной даты, если она раньше начальной
    const correctedEnd = isBefore(end, start) ? start : addDays(end, -1)

    return { start, end: correctedEnd }
  }

  const allDates: Date[] = intervals.flatMap((interval) => {
    const { start, end } = getValidInterval(interval)
    return eachDayOfInterval({ start, end })
  })

  return [...new Set(allDates)]
}

const isDateInList = (date: Date, dateList: Date[]): boolean => {
  return dateList.some((d) => isEqual(d, startOfDay(date)))
}

export const useIntervalsFilters = (start: Date | null, nodeParentId = -99) => {
  const oneDayBookLayers = useSettingsSelector(
    (settings) => settings.oneDayBookLayers,
    []
  )
  const isRestrictedLayer = oneDayBookLayers.some(
    (layerId) => layerId === nodeParentId
  )
  const timeForBooking = useMemo(
    () => getTimeForBooking(isRestrictedLayer),
    [isRestrictedLayer]
  )

  const filterByTime = (slot: Date) => {
    return timeForBooking.some((interval) =>
      isWithinInterval(slot, { start: interval.from, end: interval.to })
    )
  }

  const filterStartTime = (time: Date): boolean => {
    if (!start) return filterByTime(time)

    return timeForBooking.some((interval) =>
      isWithinInterval(time, { start: interval.from, end: interval.to })
    )
  }

  const filterEndTime = (time: Date): boolean => {
    return timeForBooking.some((interval) =>
      isWithinInterval(time, { start: interval.from, end: interval.to })
    )
  }

  const startDates = getStartIntervalDates(timeForBooking).map(startOfDay)
  const allDates = getAllIntervalDates(timeForBooking).map(startOfDay)
  const currentDates = getCurrentIntervalDates(timeForBooking, start).map(
    startOfDay
  )

  const filterStartDate = (date: Date): boolean => {
    return isDateInList(date, startDates)
  }

  const filterEndDate = (date: Date): boolean => {
    const filteredDates = start
      ? currentDates.filter((d) => d >= startOfDay(start))
      : allDates

    return isDateInList(date, filteredDates)
  }

  return {
    filterStartTime,
    filterEndTime,
    filterStartDate,
    filterEndDate,
    timeForBooking,
    isRestrictedLayer
  }
}
