import { Group, Rect } from 'react-konva'
import { FC } from 'react'
import { PointStatusProps } from './point-status.types'
import { useIconSize } from './lib/use-icon-size'

export const PointStatus: FC<PointStatusProps> = ({
  size,
  icon: IconComponent,
  color,
  strokeWidth: pointStrokeWidth
}) => {
  const { iconSize, imageSize, padding, strokeWidth } = useIconSize(
    size,
    pointStrokeWidth
  )

  return (
    <Group y={0} x={size - iconSize / 2}>
      <Rect
        width={iconSize}
        height={iconSize}
        cornerRadius={9999}
        fill="#fff"
        shadowForStrokeEnabled={false}
        perfectDrawEnabled={false}
        listening={true}
        stroke={color}
        x={0}
        y={0}
        strokeWidth={strokeWidth}
      />

      <Group x={padding} y={padding}>
        <IconComponent width={imageSize} height={imageSize} color={color} />
      </Group>
    </Group>
  )
}
