import { FC } from 'react'
import { LockIcon } from './icons/LockIcon'
import { PinIcon } from './icons/PinIcon'
import { PointProps } from './point-status.types'
import { PointStatus } from './PointStatus'

export const LockedStatus: FC<PointProps> = ({ size, color, strokeWidth }) => {
  return (
    <PointStatus
      size={size}
      color={color}
      icon={LockIcon}
      strokeWidth={strokeWidth}
    />
  )
}

export const PinnedStatus: FC<PointProps> = ({ size, color, strokeWidth }) => {
  return (
    <PointStatus
      size={size}
      color={color}
      icon={PinIcon}
      strokeWidth={strokeWidth}
    />
  )
}
