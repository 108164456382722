import React from 'react'
import styled, { css } from 'styled-components'

export const SearchPlacesIcon = ({ active, onClick, ...otherProps }) => {
  return (
    <SvgContainer $active={active} onClick={onClick} {...otherProps}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1 11.2C12.9837 11.2 13.7 10.4837 13.7 9.6C13.7 8.71634 12.9837 8 12.1 8C11.2163 8 10.5 8.71634 10.5 9.6C10.5 10.4837 11.2163 11.2 12.1 11.2ZM12.1 13.2C14.0882 13.2 15.7 11.5882 15.7 9.6C15.7 7.61177 14.0882 6 12.1 6C10.1118 6 8.5 7.61177 8.5 9.6C8.5 11.5882 10.1118 13.2 12.1 13.2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8253 12.4158C19.4358 11.2012 19.7 10.2539 19.7 9.6C19.7 5.40264 16.2974 2 12.1 2C7.90264 2 4.5 5.40264 4.5 9.6C4.5 10.2539 4.76421 11.2012 5.37466 12.4158C5.96812 13.5965 6.80406 14.865 7.74318 16.1146C9.25227 18.1225 10.9423 19.9693 12.1 21.1699C13.2577 19.9693 14.9477 18.1225 16.4568 16.1146C17.3959 14.865 18.2319 13.5965 18.8253 12.4158ZM12.7736 23.3392C14.9571 21.1532 21.7 14.0435 21.7 9.6C21.7 4.29807 17.4019 0 12.1 0C6.79807 0 2.5 4.29807 2.5 9.6C2.5 14.0435 9.24286 21.1532 11.4264 23.3392C11.802 23.7151 12.398 23.7151 12.7736 23.3392Z"
          fill="currentColor"
        />
      </svg>
    </SvgContainer>
  )
}

export const SearchUsersIcon = ({ active, onClick, ...otherProps }) => {
  return (
    <SvgContainer $active={active} onClick={onClick} {...otherProps}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 13C15.3137 13 18 10.3137 18 7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7C6 10.3137 8.68629 13 12 13Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 13C8.24148 13 5 16.4677 5 21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21C3 15.5911 6.92196 11 12 11C17.078 11 21 15.5911 21 21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21C19 16.4677 15.7585 13 12 13Z"
          fill="currentColor"
        />
      </svg>
    </SvgContainer>
  )
}

export const SearchLayersIcon = ({ active, onClick, ...otherProps }) => {
  return (
    <SvgContainer $active={active} onClick={onClick} {...otherProps}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7376 13.7204C23.0323 12.9386 23.0243 11.058 21.7228 10.2873L19.0312 8.69345L18.0121 10.4144L20.7038 12.0083L12.7063 16.8372L4.68047 11.9911L7.25362 10.4052L6.20426 8.70261L3.6311 10.2885C2.35679 11.0739 2.36526 12.9295 3.64668 13.7032L11.6725 18.5493C12.3083 18.9332 13.1044 18.9332 13.7401 18.5493L21.7376 13.7204Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7376 18.7746C23.0323 17.9928 23.0243 16.1122 21.7228 15.3415L19.0312 13.7477L18.0121 15.4686L20.7038 17.0625L12.7063 21.8914L4.68047 17.0453L7.25362 15.4594L6.20426 13.7568L3.6311 15.3427C2.35679 16.1281 2.36526 17.9837 3.64668 18.7574L11.6725 23.6035C12.3083 23.9874 13.1044 23.9874 13.7401 23.6035L21.7376 18.7746Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7514 8.65792C23.0397 7.88004 23.0397 6.0116 21.7514 5.23372L13.7401 0.396387C13.1043 0.0125214 12.3083 0.0125214 11.6725 0.396387L3.66118 5.23372C2.37289 6.0116 2.37289 7.88004 3.66118 8.65792L11.6725 13.4953C12.3083 13.8791 13.1043 13.8791 13.7401 13.4953L21.7514 8.65792ZM20.7176 6.94582L12.7063 11.7832L4.69497 6.94582L12.7063 2.10849L20.7176 6.94582Z"
          fill="currentColor"
        />
      </svg>
    </SvgContainer>
  )
}

export const SearchBookingsIcon = ({ active, onClick, ...otherProps }) => {
  return (
    <SvgContainer $active={active} onClick={onClick} {...otherProps}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 4H4L4 21H21V4ZM4 2C2.89543 2 2 2.89543 2 4V21C2 22.1046 2.89543 23 4 23H21C22.1046 23 23 22.1046 23 21V4C23 2.89543 22.1046 2 21 2H4Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 8C2 7.44772 2.44772 7 3 7H22C22.5523 7 23 7.44772 23 8C23 8.55228 22.5523 9 22 9H3C2.44772 9 2 8.55228 2 8Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4C5.44772 4 5 3.55228 5 3L5 1C5 0.447715 5.44772 1.95702e-08 6 4.37114e-08C6.55228 6.78525e-08 7 0.447715 7 1L7 3C7 3.55228 6.55228 4 6 4Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 4C18.4477 4 18 3.55228 18 3L18 1C18 0.447715 18.4477 1.95702e-08 19 4.37114e-08C19.5523 6.78525e-08 20 0.447715 20 1L20 3C20 3.55228 19.5523 4 19 4Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6823 11.2689C17.0861 11.6458 17.1079 12.2786 16.7311 12.6823L12.0644 17.6823C11.8753 17.885 11.6105 18 11.3333 18C11.0562 18 10.7914 17.885 10.6023 17.6823L8.26895 15.1823C7.89211 14.7786 7.91393 14.1458 8.31769 13.7689C8.72144 13.3921 9.35423 13.4139 9.73106 13.8177L11.3333 15.5344L15.2689 11.3177C15.6458 10.9139 16.2786 10.8921 16.6823 11.2689Z"
          fill="currentColor"
        />
      </svg>
    </SvgContainer>
  )
}

const SvgContainer = styled.div<{ $active?: boolean }>`
  display: flex;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  ${({ $active }) =>
    $active
      ? css`
          color: #1e22aa;
        `
      : css`
          color: #c5ccee;
        `}
`
