import React, { FC, PropsWithChildren } from 'react'
import ISVGIcon from './svg-icon.interface'

const SvgIcon: FC<PropsWithChildren<ISVGIcon>> = ({
  size,
  width = 16,
  height = 16,
  children,
  ...rest
}) => {
  const w = size || width
  const h = size || height

  return (
    <div style={{ color: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {React.cloneElement(children as any, {
        width: `${w / 16}rem`,
        height: `${h / 16}rem`,
        ...rest
      })}
    </div>
  )
}

export default SvgIcon
