import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { FC, useEffect, useMemo, useRef } from 'react'
import { Group, Image, Rect, Text } from 'react-konva'
import { BasicPointProps } from './basic-point.interface'
import { useMapAvatar } from './lib/use-map-avatar'
import { useEventHandlers } from './lib/use-event-handlers'
import { usePointCoordinates } from './lib/use-point-coordinates'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { isBookingPermanent } from '@/components/layout/Sidebar/Reports/reports/abstract-bookings-report/getBookingType'
import { LockedStatus, PinnedStatus } from './ui/point-status'

type PointStatusType = 'available' | 'locked' | 'pinned'

const BasicPoint: FC<BasicPointProps> = (props) => {
  const {
    colors,
    point,
    options,
    hasDepartment,
    bookingData,
    isPointBookable,
    node,
    current,
    url,
    sourceType,
    scheme
  } = props

  const {
    mode: lockMode = 'include',
    types: lockTypes = [],
    ids: lockIds = []
  } = useSettingsSelector((settings) => settings.lockStatus, {
    mode: 'include',
    types: [],
    ids: []
  })

  const isLocked = useMemo(() => {
    if (lockMode === 'all') {
      return !isPointBookable
    }

    if (isPointBookable) {
      return false
    }

    const isLockIncluded =
      lockTypes.includes(point.type_name) || lockIds.includes(point.id)

    return lockMode === 'exclude' ? !isLockIncluded : isLockIncluded
  }, [isPointBookable, lockTypes, lockIds, lockMode])

  /**
   * Получаем статус точки:
   *  недоступно для бронирования - locked
   *  закреплено за сотрудником - pinned
   *  доступно для бронирования - available
   */
  const pointStatus: PointStatusType = useMemo(() => {
    if (bookingData) {
      return isBookingPermanent(bookingData) ? 'pinned' : 'available'
    }

    return isLocked ? 'locked' : 'available'
  }, [bookingData, isLocked])

  const isSchemeObject = scheme.includes(point.type_name)
  const layers = useMapStore((state) => state.layers)
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const [width, height] = useMapStore((state) => state.size)
  const setTooltip = useMapStore((state) => state.setTooltip)

  const textRef = useRef<any>(null)
  const groupRef = useRef<any>(null)

  const size_k = (node.size_k || 1) * (point.size_k || 1)
  const { x, y, name } = point
  const { labelSize, fontSize, color, borderWidth, wrapText } = options

  const isEmployeeVisible = layers['employees']
  const { image } = useMapAvatar(
    isEmployeeVisible ? bookingData : undefined,
    node,
    url,
    sourceType
  )
  const withoutPatronymic = bookingData?.display
    ?.split(' ')
    .slice(0, 2)
    .join(' ')
  const text = isEmployeeVisible ? withoutPatronymic || name : name
  const displayText = wrapText ? text.split(' ').join('\n') : text

  const {
    onSelect,
    onMouseEnterHandler,
    onMouseLeaveHandler,
    onGroupMouseEnterHandler,
    onGroupMouseLeaveHandler
  } = useEventHandlers(
    isSchemeObject,
    setSeat,
    setSeatEmployee,
    bookingData,
    point.id,
    name,
    setTooltip
  )

  const { point: pointCoordinates, text: textCoordinates } =
    usePointCoordinates(width, height, labelSize, size_k, x, y, textRef)

  const shapeSize = width * labelSize * size_k

  const getColor = useMemo(() => {
    if (!hasDepartment || !Object.keys(colors).length) return node?.border
    const color = colors[hasDepartment]
    if (!hasDepartment || !color) return node?.border
    return color
  }, [node?.border, hasDepartment, colors])

  const radiusPercentage = useMemo(
    () => shapeSize * ((node?.radius || 100) / 100),
    [shapeSize, node?.radius]
  )

  useEffect(() => {
    if (!groupRef.current) return
    groupRef.current.on('mouseenter', onGroupMouseEnterHandler)
    groupRef.current.on('mouseleave', onGroupMouseLeaveHandler)
  }, [groupRef.current, onGroupMouseEnterHandler, onGroupMouseLeaveHandler])

  const pointColor = current ? '#ff0000' : getColor

  return (
    <Group
      x={pointCoordinates.x}
      y={pointCoordinates.y}
      offsetX={shapeSize / 2}
      offsetY={shapeSize / 2}
      onClick={onSelect}
      onTap={onSelect}
      listening={!isSchemeObject}
    >
      <Group
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        opacity={1}
        id={'point' + point.id}
        ref={groupRef}
      >
        {!isSchemeObject && (
          <Rect
            width={shapeSize}
            height={shapeSize}
            stroke={pointColor}
            strokeWidth={current ? shapeSize * 0.1 : fontSize * borderWidth}
            fill={node?.background}
            cornerRadius={radiusPercentage}
            shadowForStrokeEnabled={false}
            perfectDrawEnabled={false}
            listening={!isLocked}
          />
        )}

        {image && (
          <Image
            image={image}
            width={shapeSize}
            height={shapeSize}
            cornerRadius={radiusPercentage}
          />
        )}

        {pointStatus == 'pinned' && (
          <PinnedStatus
            size={shapeSize}
            color={pointColor}
            strokeWidth={fontSize * borderWidth}
          />
        )}
        {pointStatus == 'locked' && (
          <LockedStatus
            size={shapeSize}
            color={pointColor}
            strokeWidth={fontSize * borderWidth}
          />
        )}
      </Group>

      {!isSchemeObject && (
        <Text
          ref={textRef}
          text={displayText}
          align="center"
          fontSize={fontSize}
          fill={color}
          x={textCoordinates.x}
          y={textCoordinates.y}
          listening={!isLocked}
          perfectDrawEnabled={false}
        />
      )}
    </Group>
  )
}

export default BasicPoint
