import styled, { css } from 'styled-components';

const ButtonBaseStyles = css`
    border: none;
    outline: none;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    padding: 10px 20px;
    cursor: pointer;

    min-height: 40px;

    &:disabled {
        opacity: 0.7;
    }
`

export const ButtonBase = styled.button<{ $fullWidth?: boolean }>`
    ${ButtonBaseStyles}

    ${({ $fullWidth }) => $fullWidth && css`
        width: 100%;
    `}
`