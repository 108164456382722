import { translate } from "@/i18n"
import styled from "styled-components"

export const NoResults = () => (
	<tr>
		<td colSpan={4}>
			<NotFound>{translate('no-results')}</NotFound>
		</td>
	</tr>
)

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`