import React from 'react'
import styled, { css } from 'styled-components'
import { FieldProps, getIn } from 'formik';

export interface InputProps {
    $transparent?: boolean
    $fullWidth?: boolean
    as?: "input" | "textarea" | undefined
    $error?: boolean
}

export type InputFieldProps = {
    customError?: string
    textarea?: boolean
}

export const Input = styled.input<InputProps>`
    padding: 1rem;
    outline: none;
    border: none;
    border: 1px solid ${props => props.theme.palette.text.label};
    display: block;
    border-radius: 8px;
    min-height: 40px;
    font-size: 16px;
    line-height: 16px;
    color: #838D96;

    ${({ $transparent }) => $transparent ? css`
        background: transparent;
        color: ${props => props.theme.palette.text.label};
        border: 1px solid ${props => props.theme.palette.text.label};

        &::placeholder {
            color: #e3e3e3;
        }
    ` : css`
        background: #fff;
        color: ${props => props.theme.palette.text.secondary};
        border: 1px solid ${props => props.theme.palette.text.label};
    `};

    ${({ $fullWidth }) => $fullWidth && css`
        width: 100%;
    `}

    ${({ $error }) => $error && css`
        border-color: rgb(251, 105, 94);
    `}
`

export const InputField = ({ field, form, textarea, ...props }: FieldProps & InputProps & InputFieldProps) => {
    const { customError, ...otherProps } = props
    const errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)

    return (
        <InputWrapper>
            <Input
                {...field}
                {...otherProps}
                as={textarea ? 'textarea' : 'input'}
                $error={errorMessage != null || customError != null}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {customError && <ErrorMessage>{customError}</ErrorMessage>}
        </InputWrapper>
    )
}

export const InputWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: 100%;
`

export const ErrorMessage = styled.div`
    line-height: 24px;
    color: rgb(251,105,94);
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 5px;
    border-radius: 3px;
    transition: background-color 0.15s ease-in-out 0s;
`